/*
 * Home
 */


//==================================================
//                     IMPORTS
//==================================================

@import 'import/variables'
@import "../../../../bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins"
@import 'import/scss-mixins'
@import 'import/mixins'
@import 'import/components'

//==================================================
//                     HEADER
//==================================================

//
// See Home Page Header in variables
// --------------------------------------------------
//$top-bar-phone-font-size,
//$top-bar-email-font-size,
//keep search-bar in a group
#search-bar
    .contact
        display: block
        order: -1
        margin-left: auto
        margin-right: auto
        margin-bottom: 1rem

        a
            margin-left: auto
            margin-right: auto

        a[href^="tel"]
            font-size: calc(#{$top-bar-phone-font-size} * 1.5)
        a[href^="mailto"]
            font-size: calc(#{$top-bar-email-font-size} * 1.5)

    .language
        align-self: center
        margin-top: 0
        margin-left: auto
        margin-right: auto
    .search
        margin-left: auto
        margin-right: auto
        width: 75%

#search-bar
    & > .container
        @media screen and (max-width: 570px)
            justify-content: center

    @media screen and (min-width: 535px)
        .contact
            order: 1
            margin-bottom: 0
            a
                display: block
            a[href^="tel"]
                font-size: $top-bar-phone-font-size
            a[href^="mailto"]
                font-size: $top-bar-email-font-size
        .search
            max-width: 50%


    @media screen and (min-width: #{$screen-lg-min})
        position: absolute
        top: 0
        left: 0

        form[role="search"]
            margin-left: 0
            margin-right: 0

        & > .container
            //make room for brand browser
            padding-left: $nav-position-from-left

.masthead__logo
    margin-bottom: $top-logo-margin-bottom
    text-align: center
    height: $top-logo-height
    display: block
    img
        height: 100%

#homepage-masthead

    //Position header navs relative to this div
    position: relative

    @media screen and (min-width: #{$screen-lg-min})
        & > .container:first-of-type
            position: relative

    //Oh Microsoft Edge, don't ever change
    z-index: 1

    @media screen and (max-width: #{$screen-md-max})
        .container
            +unskew
            width: 100%
            padding-left: 0
            padding-right: 0

        .brands
            padding-top: $slogan-bottom-margin

            .skew-content
                padding-top: 0

    //car parts brand switcher
    .brands

        z-index: 2
        background-color: #fff

        display: flex
        justify-content: center
        flex-direction: column

        @media screen and (min-width: #{$screen-lg-min})
            height: $nav-brand-height
            width: $nav-brand-width
            +three-stripes-left($outer-stripe-color, $nav-brand-outer-stripe-width-left, $middle-stripe-color,  $nav-brand-middle-stripe-width-left, $inner-stripe-color, $nav-brand-inner-stripe-width-left)
            +three-stripes-right($inner-stripe-color, $nav-brand-inner-stripe-width-right, $middle-stripe-color, $nav-brand-middle-stripe-width-right, $outer-stripe-color, $nav-brand-outer-stripe-width-right)
            left: calc(((100% - #{$container-lg}) / 2) + #{$brands-desktop-left})


        .skew-content
            padding: $nav-brand-padding-top 1rem 0
            transform-origin: 0 1rem * 2

        .slogan
            @include line-filler('auto', #333)
            margin-bottom: 4rem

            line-height: 1
            font-size: $slogan-font-size
            letter-spacing: 0.3rem
            font-weight: bold

            @media screen and (min-width: #{$screen-lg-min})
                margin-bottom: $slogan-bottom-margin

        .brand-selector
            margin-top: 0
            margin-bottom: $nav-brand-label-margin-bottom
            +line-filler(100%, #333)
            font-size: $nav-brand-label-font-size
            line-height: 1
            letter-spacing: 0.2rem
            width: 100%

            @media screen and (min-width: #{$screen-lg-min})
                width: 95%
                +move-left(1rem)

        p.slogan + section

            @media screen and (max-width: #{$screen-lg-min})
                text-align: center

            a
                max-width: 50%
                margin-bottom: $brand-logo-margin-bottom
                display: inline-block

                &:link, &:visited
                    filter: opacity(80%)

                &:hover, &:active
                    filter: opacity(100%)


                @media screen and (max-width: #{$screen-lg-min})
                    width: 30%

            //icon placement
            @media screen and (min-width: #{$screen-lg-min})
                a:nth-of-type(odd)
                    margin-right: $brands-vertical-space

                a:first-of-type
                    margin-left: 1.3rem
                a:nth-of-type(3)
                    margin-left: -0.6rem
                    margin-right: $brands-vertical-space - 1rem
                a:nth-of-type(5)
                    margin-left: -2.4rem
                    margin-right: $brands-vertical-space + 0.7rem
            img
                height: $brand-logo-height
        .btn
            margin: 0 auto 0.7rem
            padding: 0.7rem 2rem
            padding-top: $nav-brand-padding-bottom
            padding-bottom: $nav-brand-padding-bottom

    .import-slogan
        position: absolute

        color: #fff
        font-style: italic
        text-align: right
        line-height: 1
        text-shadow: 1px 1px 1px rgba(0,0,0,0.5)

        span
            //give each span its own line
            display: block

        span:first-of-type
            margin-bottom: 0.6rem
            font-weight: 300

        span:nth-of-type(2),
        span:nth-of-type(3)
            font-weight: bold
            font-weight: 900
        span:nth-of-type(3)
            margin-right: 1.7rem

        $import-slogan-height: (4rem * 2) + 2rem
        @media (max-width: #{$screen-md-max})
            //this brings it exactly to the bottom of the slideshow div
            //bottom: calc(0px - ((#{$slider-mobile-height} - #{$import-slogan-height}) / 2))
            bottom: calc((0px - #{$slider-mobile-height} - #{$import-slogan-height}) / 2)

            right: 1rem
            font-size: 2rem
            span:first-of-type
                margin-bottom: 0.3rem
                font-weight: 700
            span:nth-of-type(2),
            span:nth-of-type(3)
                font-size: 3.4rem

        @media screen and (min-width: #{$screen-lg-min})
            bottom: 3rem
            right: 10rem
            font-size: 3rem
            span:first-of-type
                margin-bottom: 0.6rem
            span:nth-of-type(2),
            span:nth-of-type(3)
                font-size: 6rem
            span:nth-of-type(3)
                margin-right: 2.7rem


        //undo some skew-container things
        height: auto
        //see margin-left of next span
        overflow: visible

    // Since this whole thing breaks below 1200, let's fix it
    @media screen and (max-width: #{$screen-md-max})

        .brands
            position: relative
            width: 100%
            height: auto



//
// Slider
//

$slider-content-extra-width: ($nav-brand-height - $top-bar-height) / tan(79)
//$slider-image-width: 40%
//
.header-images
    width: 100%
    text-align: center
    overflow: hidden

    @media screen and (min-width: #{$screen-lg-min})
        // keeps height relative to search bar height
        // and brands nav height
        height: $nav-brand-height - $top-bar-height

    @media screen and (max-width: #{$screen-md-max})
        //no need for skew in mobile
        +unskew
        height: $slider-mobile-height
        .skew-container
            display: none
            &.active
                display: inline-block

            .skew-content
                margin-left: 0
                background-size: cover

    //Position header image slider
    @media screen and (min-width: #{$screen-lg-min})
        position: absolute
        z-index: 0

        //make room for language switcher, search, etc.
        top: $top-bar-height


    display: flex

    .skew-container
        //width: $slider-image-width
        //margin-left: -2rem
        filter: brightness(0.5)
        position: relative

        //make skew-container same height as whole slider
        height: 100%
        @media screen and (min-width: $screen-lg-min)
            &:last-of-type
                margin-right: -$slider-content-extra-width

            width: calc((100% - #{$container-lg}) + #{$slider-content-extra-width})

        &.active
            filter: unset
            @media screen and (max-width: $screen-sm-min)
                width: 100%
            @media screen and (min-width: $screen-sm-min)
                width: $container-sm
                margin-left: auto
                margin-right: auto
            @media screen and (min-width: $screen-md-min)
                width: $container-md
            @media screen and (min-width: $screen-lg-min)
                width: $container-lg

    .skew-content

        //make skew-content same height as skew-container
        height: 100%
        //make sure skewed lines line up with each other; no space between elements
        width: calc(100% + #{$slider-content-extra-width})
        margin-left: -$slider-content-extra-width

        background-position: center bottom 0px
        background-size: cover
        background-repeat: no-repeat


    //TODO: move these to inline css if they come from drive system
    .one.skew-content
        background-image: url('/assets/theme/img/slider-1.jpg')

    .two.skew-content
        background-image: url('/assets/theme/img/slider-2.jpg')

    .three.skew-content
        background-image: url('/assets/theme/img/slider-3.jpg')

//==================================================
//                    CONTENT
//==================================================

#new-arrivals

    +polka-dot-background-speedometer
    +dark-background-section-header

    //undo default padding
    padding-bottom: 0

    //bring content in front of speedometer
    .container
        z-index: 1

    h2
        margin-top: 4.5rem
        border-bottom-color: #fff
        position: relative


    +product-listing-divider-line
    +product-listing-dark-background

    $new-arrivals-category-icon-size: 4rem
    nav.category-nav

        //keep links in line
        width: 100%
        clear: both
        display: inline-block

        //iphone 5s adds space
        position: relative
        bottom: -5px

        padding-top: 5rem
        font-size: 2rem
        font-weight: bold

        @media screen and (min-width: #{$screen-xs-min}) and (max-width: #{$screen-sm-max})
            +divider-line-to-bottom
            &::after
                left: 50%

        ul
            +clearfix
            width: 100%

        li
            +make-xs-column(6)
            +make-md-column(3)
            padding-left: 0
            padding-right: 0
            padding-bottom: 1rem

            font-size: 2.2rem

            //put lines between all buttons
            @media screen and (min-width: #{$screen-md-min})
                +divider-line-to-bottom

            &:last-of-type::after
                display: none

            a:before
                margin-bottom: 1rem
                width: $new-arrivals-category-icon-size
                height: $new-arrivals-category-icon-size

#featured-items
    padding-top: 10rem
    background-color: #fff
    +light-background-section-header
    +light-background-text
    +background-tire-tracks

    +product-listing-divider-line

