/*!
 * Components
 */

@import 'variables'
@import 'mixins'
@import 'bootstrap'

//==================================================
//                     ALERTS
//==================================================



//==================================================
//                     BADGES
//==================================================

//==================================================
//                     BANNERS
//==================================================

#single-header-image
    height: 25rem
    background-position: center
    background-repeat: no-repeat
    background-size: cover

//==================================================
//                   BREADCRUMB
//==================================================

.breadcrumb
    line-height: 1
    font-size: 1.7rem
    li
        display: inline-block
    li.active
        margin-top: 0.3rem
        display: block
        font-size: 3rem
        font-weight: bold

    li::after
        display: inline-block
        content: '\002f'
        margin-left: 0.3rem
        margin-right: 0.3rem
    li:last-of-type::after
        display: none

//==================================================
//                     BUTTONS
//==================================================

.btn
    text-transform: uppercase

.btn-xlg
    width: 100%

    // stay in the center!
    float: none !important

    // make it all smooth
    transition: all 1s
    &:hover
        transition: all 1s

// Check /fr/accessoires/roues. See category buttons
nav.button-list
    +make-md-column(8)

    //same as #brand-selector-dropdown
    margin-top: $line-height-computed * 2

    clear: left

    ul
        display: flex
        flex-wrap: wrap
    li
        width: auto
        display: inline-block
        margin-bottom: $line-height-computed / 2

        //space it out
        margin-right: 2rem
        &:last-of-type
            margin-right: 0

        a
            display: block
            font-weight: bold

        //expects default bootstrap button
        a.btn:link, a.btn:visited
            color: #fff
        a.btn:hover, a.btn:active
            color: #fff

    a.active
        &.btn:link, &.btn:visited
            border: $brand-primary solid 1px
            background-color: #fff
            color: $brand-primary
            box-shadow: none
        &.btn:hover, &.btn:active
            background-color: $brand-primary
            color: #fff

//==================================================
//                 CORNER RIBBON
//==================================================

/*https://codepen.io/eode9/pen/twkKm*/

$corner-ribbon-width: 20rem
.corner-ribbon
    width: $corner-ribbon-width
    background: $brand-warning
    position: absolute
    top: $corner-ribbon-width / 4
    left: -4rem
    text-align: center
    letter-spacing: 1px
    color: #fff
    transform: rotate(-45deg)
    padding-top: 0.2rem
    padding-bottom: 0.2rem
    z-index: 2

    .shadow
        box-shadow: 0 0 3px rgba(0,0,0,.3)

    .top-left
        top: 25px
        left: -50px
        transform: rotate(-45deg)

//==================================================
//                CURRENCY SWITCHER
//==================================================

#formSelectCurrency 
    .bootstrap-select
        max-width: 13rem
    &.follow
        position: fixed
        right: 0
        top: 20%
        z-index: 5

//==================================================
//                DEVELOPMENT ONLY
//==================================================

.development
    display: none

//==================================================
//                      FORMS
//==================================================

//see custom selects in theme.twig
=select-container

    //don't look behind the curtain
    position: relative
    overflow: hidden

    //nope, no trickery to suspect here, user
    cursor: pointer

    select
        width: 100%

        //we can hide the default arrow
        //we have the technology!
        appearance: none
        &::-ms-expand
            display: none

        //undo bs defaults
        background-color: transparent
        border: 0

        //someone's got parent issues
        cursor: pointer

    //The last font-awesome icon
    //is assumed to be the down arrow
    .fa:last-of-type
        display: inline
        color: $input-border

        //put this on the right
        position: absolute
        right: 0.5rem
        top: 0.25rem

        //don't block the select behind it
        pointer-events: none

.select-container
    +select-container

div.select-container.dark
    +select-container
    background-color: $brand-primary
    font-weight: 900
    color: #fff
    .fa:last-of-type
        color: #fff


//==================================================
//                     HEADERS
//==================================================

//no section header? no problem
section.section-products.no-section-header
    padding-top: $section-padding-top + $section-header-height

/*
 *<section>
 *	<h2 id="section-id" class="skew-container"><span>
 *		<span class="skew-content">
 *			Section Title
 *		 </span>
 *	</span></h2>
 *
 *
 *</section>
 *
 */

=section-header
    & > h2
        //don't want 100% width
        display: inline-block

        margin-bottom: 3.75rem
        margin-right: auto

        color: $brand-primary
        font-size: $section-header-font-size
        font-weight: bold
        font-style: italic

        +outside-container-margin('padding-left')

        //border-bottom
        //if you use a real border
        //you'll get a gap
        &::after
            display: block
            content: ''
            position: absolute
            bottom: 0
            right: 0
            width: 100%
            height: $section-header-underline-width

        & > span
            display: block

            padding-top: $section-header-padding-up-down
            padding-bottom: calc(#{$section-header-padding-up-down} - #{$section-header-underline-width})
            padding-right: $section-header-padding-left-right
            padding-left: $section-header-padding-left-right


=dark-background-section-header
    +section-header
    & > h2

        //make room for stripe
        @media screen and (max-width: $screen-sm-min)
            padding-left: $section-header-padding-left
        @media screen and (min-width: $screen-sm-min)
            padding-left: calc((100% - #{$container-sm}) / 2 + (#{$panel-body-padding} * 2))
        @media screen and (min-width: $screen-md-min)
            padding-left: calc((100% - #{$container-md}) / 2 + (#{$panel-body-padding} * 2))
        @media screen and (min-width: $screen-lg-min)
            padding-left: calc((100% - #{$container-lg}) / 2 + (#{$panel-body-padding} * 2))

        //make underline white
        &::after
            background-color: #fff

        //stripes
        & > span
            background-color: #fff
            +three-stripes-left($outer-stripe-color, $section-header-outer-stripe, $middle-stripe-color, $section-header-middle-stripe, $inner-stripe-color, $section-header-inner-stripe)

=light-background-section-header
    +section-header
    & > h2
        &::after
            background-color: $brand-primary


//==================================================
//                LANGUAGE SWITCHER
//==================================================

ul.language
    padding: 0
    list-style-type: none
    margin-bottom: 0

//==================================================
//                      MEDIA
//==================================================

//==================================================
//                   NAVIGATION
//==================================================

//normalize
nav ul
    padding: 0
    list-style-type: none
    margin-bottom: 0

//nav with icon above each category
nav.category-nav
    +hover-container-links(0.8)

    ul
        list-style: none
        padding: 0
        margin-left: -0.5rem

    li
        display: inline-block
        text-align: center
        position: relative

        &:last-of-type::after
            content: ''
            width: 0
            height: 0
            background: none

        a
            //defaults
            color: #fff

            //stop weird widths
            display: inline-block

        //icon

        a::before
            +icon

            display: block
            margin-left: auto
            margin-right: auto
            width: 3rem
            height: 3rem


//==================================================
//                     PANELS
//==================================================



//==================================================
//                   PAGINATION
//==================================================



//==================================================
//                    POPOVERS
//==================================================

//==================================================
//                    PRODUCTS
//==================================================

/**
 * see _product_listing.twig
 * default colors are for a light background
 * see =product-listing-dark-background
 */

.product-listing

    color: #333

    &:hover
        color: #333

    margin-bottom: 5rem

    background-color: transparent
    text-transform: uppercase
    text-align: center

    display: inline-block
    vertical-align: top

    +make-sm-column(6)
    +make-md-column(4)

    &.max-four
        +make-lg-column(3)

    float: none

    //Bootstrap doesn't like inline-block
    @media screen and (min-width: #{$screen-lg-min})
        &[class*="col-"]
            margin-left: -5px

    .photo
        position: relative
        overflow: hidden
        width: 100%
        height: auto
        max-height: 25rem

        //keep photo in the center even if it exceeds box dimensions
        display: flex
        align-items: center

        // border around photo
        &::before
            display: block
            content: ''
            position: absolute
            top: 0
            right: 0
            bottom: 0
            left: 0
            z-index: 2
            background-color: transparent
            border: $product-image-outline-width solid rgba(255,255,255, 0.8)

        img
            width: 100%
            height: auto
            transition: all .2s ease-in
            transform: scale(1)

    &:hover img
        transition: all .2s ease-in
        transform: scale(1.1)

    a:hover
        text-decoration: none

    h3
        font-weight: bold

    .additional-info
        display: inline-block

    .price-only, .additional-info
        display: inline-block
        padding: 0.2rem 1.2rem
        border: 1px solid #000

        &:hover
            background-color: $gray-lighter

    .price-only, .number-available, .price
        font-weight: bold
        color: $brand-primary

    .number-available, .price
        font-size: 1.7rem

    .label
        color: #333
        padding: 0.2rem 0 0 0.2rem
        .regular
            font-weight: normal
        .light
            font-weight: lighter

.product-listing.sold

    background-color: #fff

    // have to add filter to elements individually
    // or outline weirds out
    img
        filter: opacity(80%)

    h3
        filter: opacity(80%)

    //this now contains sold out message
    p
        padding: 1rem
        //border: 2px solid
        font-size: 3rem
        font-weight: bold
        filter: opacity(80%)
        font-style: normal

#new-arrivals .product-listing.sold
    background-color: transparent

=product-listing-divider-line
    .product-listing
        p:first-of-type
            margin-top: 1rem
            padding-top: 1rem
            border-top-width: 2px
            border-top-style: solid
            border-color: rgba(0,0,0,0.1)

=product-listing-dark-background
    .product-listing
        .photo::before
            border: $product-image-outline-width solid transparentize($brand-primary, 0.5)
        h3 a
            color: #fff
        p:first-of-type
            border-color: #fff
        .price-only, .additional-info
            background-color: #fff
            border: none

// different layout for products in multiple categories
.horizontal-listing
    .product-listing
        +make-xs-column(12)
        +make-sm-column(12)
        +make-md-column(12)
        +make-lg-column(12)

        @media screen and (min-width: #{$screen-sm-min})
            article
                display: flex

            .photo
                //this is already 100%
                //so just shrink that
                width: 25%

            .additional-info
                width: 15%
                display: flex
                flex-direction: column
                flex-wrap: wrap
                align-items: center

            .description
                width: 60%
                padding-left: 2rem
                padding-right: 2rem
                word-wrap: break-word

            .additional-info, .price-only
                border: none

            //h3 span
            //	+line-left-to-right(#333)

            &.sold
                p
                    display: inline-block
                    font-style: normal
                //add a placeholder for
                //missing .additional-info
                &::after
                    content: ''
                    display: block
                    height: 100%
                    width: 15%

            .additional-info
                padding-left: 0
                padding-right: 0

                // add a line under items available
                .label
                    position: relative
                    display: inline-block
                .label::after
                    display: block
                    content: ''
                    position: absolute
                    bottom: -2px
                    left: 0
                    background-color: #333
                    width: 0
                    height: 2px
                    transition: all 0.2s ease-out
                &:hover .label::after
                    height: 2px
                    width: 100%


            .number-available
                margin-top: 20px
                width: 100%
                display: block

                padding-top: 0

                display: block
                font-size: 4rem
                line-height: 1

            .label
                font-size: 1.5rem
                white-space: normal

            .light
                margin-top: 20px

            .price
                font-size: 3.5rem


//==================================================
//                SKEWED CONTAINERS
//==================================================


// skew box without skewing content
.skew-container
    height: 100%
    transform: skew(-11deg)
    // keep skew-content from overflowing
    overflow: hidden

    //needed by inline elements
    display: inline-block

    transform-origin: 0 0

.skew-content
    //skew backwards so that content isn't tilted
    transform: skew(11deg)

    //needed by inline elements
    display: inline-block

    transform-origin: 0 0

=unskew
    .skew-container
        transform: skew(0)
    .skew-content
        transform: skew(0)

//==================================================
//                     TABLES
//==================================================



//==================================================
//                      TABS
//==================================================



//==================================================
//                    TOOLTIPS
//==================================================


//==================================================
//                     VIDEO
//==================================================

// see https://alistapart.com/article/creating-intrinsic-ratios-for-video
.video-wrapper
    position: relative
    height: 0
    iframe
        border: 0
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.video-wrapper.widescreen
    padding-bottom: 56.25%
.video-wrapper.four-by-three
    padding-bottom: 75%


//==================================================
//                     WELLS
//==================================================


